import Vue from 'vue'
import {
  Button,
  Icon,
  Swipe,
  SwipeItem,
  Collapse,
  CollapseItem,
  Popup,
  Cell,
  CellGroup,
  Lazyload,
  Pagination,
  RadioGroup,
  Radio,
  Image,
  Field,
  Uploader,
  DatetimePicker,
  Picker,
  Toast,
  NavBar,
  List,
  Cascader,
  Dialog,
  Tabs,
  Tab
} from 'vant'

Vue.use(Button)
Vue.use(Icon)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Popup)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Lazyload)
Vue.use(Pagination)
Vue.use(Image)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Toast)
Vue.use(Uploader)
Vue.use(Field)
Vue.use(DatetimePicker)
Vue.use(Picker)
Vue.use(NavBar)
Vue.use(List)
Vue.use(Cascader)
Vue.use(Dialog)
Vue.use(Tabs)
Vue.use(Tab);
 
