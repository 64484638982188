import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios'
import {
  Toast
} from 'vant'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // newsApi:"http://new.4009005258.com/api/",
    newsApi: "/newsApi/",
    // ocApi:"http://shopapi.4009005258.com/v1/",
    ocApi: "/ocApi/",
    // houseApi:"http://fangapi.4009005258.com/v1/",
    houseApi: "/houseApi/",
	// mallApi:"http://mall.xiaoyuan.com.cn/index.php",
	mallApi: "/mallApi/",
    user_info: null,
    access_token: null,
    customer_service: null,
  },
  mutations: {
    updateUserInfo: function (state, user_info) {
      state.user_info = user_info
    },
    updateAccessToken: function (state, access_token) {
      state.access_token = access_token
    },
    updateCustomerService: function (state, customer_service) {
      state.customer_service = customer_service
    },
  },
  actions: {
    loginLogout: async function (context, user_info) {
      if (user_info) {
        context.commit('updateUserInfo', user_info)
        context.commit('updateAccessToken', user_info.access_token)
        localStorage.setItem("user_info", JSON.stringify(user_info))
        let customer_service = await context.dispatch('getService')
        if (customer_service.data.status == 1) {
          context.commit('updateCustomerService', customer_service.data.data)
        }
      } else {
        context.commit('updateUserInfo', null)
        context.commit('updateAccessToken', null)
        context.commit('updateCustomerService', null)
        localStorage.removeItem('user_info');
        Vue.$cookies.remove("xy_access_token");
      }
    },
    isLogin: function (context) {
      let access_token = Vue.$cookies.get("xy_access_token");
      if (access_token) {
        Axios.get(context.state.ocApi + 'account/token/islogin', {
            params: {
              'access-token': access_token
            }
          })
          .then(res => {
            if (res.data.status == 1) {
              let user_info = res.data.customer
              context.dispatch('loginLogout', user_info)
            }
          })
      }
    },
    updateMember: function (context) {
      if (context.state.user_info) {
        Axios.get(context.state.ocApi + 'account/me', {
            params: {
              'telephone': context.state.user_info.telephone
            }
          })
          .then(res => {
            if (res.data.status == 1) {
              let user_info = res.data.data
              context.dispatch('loginLogout', user_info)
            } else {
              Toast.fail(res.data.message)
            }
          })
      }
    },
    getService: function (context) {
      return Axios.get(context.state.ocApi + 'account/customer_service', {
        params: {
          'telephone': context.state.user_info.telephone
        }
      })
    }
  },
})