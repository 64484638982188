/**
 * 常用工具类函数封装
 * author huangjun
 * 
 */
// import accounting from "./accounting.js";

// accounting.settings = {
// 	currency: {
// 		symbol : "￥",   // default currency symbol is '$'
// 		format: "%s%v", // controls output: %s = symbol, %v = value/number (can be object: see below)
// 		decimal : ".",  // decimal point separator
// 		thousand: ",",  // thousands separator
// 		precision : 0   // decimal places
// 	},
// 	number: {
// 		precision : 0,  // default precision on numbers is 0
// 		thousand: ",",
// 		decimal : "."
// 	}
// }

const utils = {
	// 和PHP一样的时间戳格式化函数
	// @param {string} format 格式
	// @param {int} timestamp 要格式化的时间 默认为当前时间
	// @return {string} 格式化的时间字符串
	date: function(format, timestamp) {
		var jsdate = ((timestamp) ? new Date(timestamp * 1000) : new Date());
		var pad = function(n, c) {
			if ((n = n + "").length < c) {
				return new Array(++c - n.length).join("0") + n;
			} else {
				return n;
			}
		};
		var txt_weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

		var txt_ordin = {
			1: "st",
			2: "nd",
			3: "rd",
			21: "st",
			22: "nd",
			23: "rd",
			31: "st"
		};

		var txt_months = ["", "January", "February", "March", "April", "May", "June", "July", "August", "September",
			"October", "November", "December"
		];

		var f = {
			// Day
			d: function() {
				return pad(f.j(), 2);
			},
			D: function() {
				var t = f.l();
				return t.substr(0, 3);
			},
			j: function() {
				return jsdate.getDate();
			},
			l: function() {
				return txt_weekdays[f.w()];
			},
			N: function() {
				return f.w() + 1;
			},
			S: function() {
				return txt_ordin[f.j()] ? txt_ordin[f.j()] : 'th';
			},
			w: function() {
				return jsdate.getDay();
			},
			z: function() {
				return (jsdate - new Date(jsdate.getFullYear() + "/1/1")) / 864e5 >> 0;
			},

			// Week
			W: function() {
				var a = f.z(),
					b = 364 + f.L() - a;
				var nd2, nd = (new Date(jsdate.getFullYear() + "/1/1").getDay() || 7) - 1;

				if (b <= 2 && ((jsdate.getDay() || 7) - 1) <= 2 - b) {
					return 1;
				} else {

					if (a <= 2 && nd >= 4 && a >= (6 - nd)) {
						nd2 = new Date(jsdate.getFullYear() - 1 + "/12/31");
						return utils.date("W", Math.round(nd2.getTime() / 1000));
					} else {
						return (1 + (nd <= 3 ? ((a + nd) / 7) : (a - (7 - nd)) / 7) >> 0);
					}
				}
			},

			// Month
			F: function() {
				return txt_months[f.n()];
			},
			m: function() {
				return pad(f.n(), 2);
			},
			M: function() {
				var t = f.F();
				return t.substr(0, 3);
			},
			n: function() {
				return jsdate.getMonth() + 1;
			},
			t: function() {
				var n;
				if ((n = jsdate.getMonth() + 1) == 2) {
					return 28 + f.L();
				} else {
					if (n & 1 && n < 8 || !(n & 1) && n > 7) {
						return 31;
					} else {
						return 30;
					}
				}
			},

			// Year
			L: function() {
				var y = f.Y();
				return (!(y & 3) && (y % 1e2 || !(y % 4e2))) ? 1 : 0;
			},
			//o not supported yet
			Y: function() {
				return jsdate.getFullYear();
			},
			y: function() {
				return (jsdate.getFullYear() + "").slice(2);
			},

			// Time
			a: function() {
				return jsdate.getHours() > 11 ? "pm" : "am";
			},
			A: function() {
				return f.a().toUpperCase();
			},
			B: function() {
				// peter paul koch:
				var off = (jsdate.getTimezoneOffset() + 60) * 60;
				var theSeconds = (jsdate.getHours() * 3600) +
					(jsdate.getMinutes() * 60) +
					jsdate.getSeconds() + off;
				var beat = Math.floor(theSeconds / 86.4);
				if (beat > 1000) beat -= 1000;
				if (beat < 0) beat += 1000;
				if ((String(beat)).length == 1) beat = "00" + beat;
				if ((String(beat)).length == 2) beat = "0" + beat;
				return beat;
			},
			g: function() {
				return jsdate.getHours() % 12 || 12;
			},
			G: function() {
				return jsdate.getHours();
			},
			h: function() {
				return pad(f.g(), 2);
			},
			H: function() {
				return pad(jsdate.getHours(), 2);
			},
			i: function() {
				return pad(jsdate.getMinutes(), 2);
			},
			s: function() {
				return pad(jsdate.getSeconds(), 2);
			},
			//u not supported yet

			// Timezone
			//e not supported yet
			//I not supported yet
			O: function() {
				var t = pad(Math.abs(jsdate.getTimezoneOffset() / 60 * 100), 4);
				if (jsdate.getTimezoneOffset() > 0) t = "-" + t;
				else t = "+" + t;
				return t;
			},
			P: function() {
				var O = f.O();
				return (O.substr(0, 3) + ":" + O.substr(3, 2));
			},
			//T not supported yet
			//Z not supported yet

			// Full Date/Time
			c: function() {
				return f.Y() + "-" + f.m() + "-" + f.d() + "T" + f.h() + ":" + f.i() + ":" + f.s() + f.P();
			},
			//r not supported yet
			U: function() {
				return Math.round(jsdate.getTime() / 1000);
			}
		};

		return format.replace(/[\\]?([a-zA-Z])/g, function(t, s) {
			var ret;
			if (t != s) {
				// escaped
				ret = s;
			} else if (f[s]) {
				// a date function exists
				ret = f[s]();
			} else {
				// nothing special
				ret = s;
			}

			return ret;
		});
	},
	// 验证邮箱
	isEmail: function(emailStr) {
		var reg = /^[a-zA-Z0-9]+([._-]*[a-zA-Z0-9]*)*@[a-zA-Z0-9]+.[a-zA-Z0-9{2,5}$]/;
		var result = reg.test(emailStr);
		if (result) {
			return true;
		} else {
			return false;
		}
	},
	// 验证手机号码
	isMobile: function(phone) {
		var reg = /^1\d{10}$/;
		if (reg.test(phone)) {
			return true;
		} else {
			return false;
		}
	},
	// 数字转金钱
	// toMoney: function(val) {
	// 	return accounting.formatMoney(val);
	// },
	// 金钱格式转数字串
	// toNumber: function(val) {
	// 	return accounting.unformat(val);
	// },
	// 数字前补零
	zeroFill: function(num, length) {
		return (Array(length).join('0') + num).slice(-length);
	},
	// 判断数据类型 [Array,Object,String,Date,RegExp,Function,Boolean,Number,Null,Undefined]
	dataType: function(boj) {
		return Object.prototype.toString.call(boj).slice(8, -1);
	},
	// 深拷贝
	objDeepCopy: function(obj, deep = true) {
		if (utils.dataType(obj) == 'Function') {
			return new Function("return " + obj.toString())();
		} else if (obj === null || (typeof obj !== "object")) {
			return obj;
		} else {
			var name, target = utils.dataType(obj) == 'Array' ? [] : {},
				value;

			for (name in obj) {
				value = obj[name];

				if (value === obj) {
					continue;
				}

				if (deep) {
					if (utils.dataType(value) == 'Array' || utils.dataType(value) == 'Object') {
						target[name] = utils.objDeepCopy(value, deep);
					} else if (utils.dataType(value) == 'Function') {
						target[name] = new Function("return " + value.toString())();
					} else {
						target[name] = value;
					}
				} else {
					target[name] = value;
				}
			}
			return target;
		}
	},
	//判断空对象
	isNullObject: function(obj) {
		if (JSON.stringify(obj) == "{}") {
			return true
		} else {
			return false
		}
	},
	/**
	 * 解决小数精度问题
	 * @param {*数字} a
	 * @param {*数字} b
	 * @param {*符号} sign
	 * fixedFloat(0.3, 0.2, '-')
	 */
	fixedFloat: function(a, b, sign) {
		function padding0(p) {
			var z = ''
			while (p--) {
				z += '0'
			}
			return z
		}

		function handle(x) {
			var y = String(x)
			var p = y.lastIndexOf('.')
			if (p === -1) {
				return [y, 0]
			} else {
				return [y.replace('.', ''), y.length - p - 1]
			}
		}
		// v 操作数1, w 操作数2, s 操作符, t 精度
		function operate(v, w, s, t) {
			switch (s) {
				case '+':
					return (v + w) / t
				case '-':
					return (v - w) / t
				case '*':
					return (v * w) / (t * t)
				case '/':
					return (v / w)
			}
		}

		var c = handle(a)
		var d = handle(b)
		var k = 0

		if (c[1] === 0 && d[1] === 0) {
			return operate(+c[0], +d[0], sign, 1)
		} else {
			k = Math.pow(10, Math.max(c[1], d[1]))
			if (c[1] !== d[1]) {
				if (c[1] > d[1]) {
					d[0] += padding0(c[1] - d[1])
				} else {
					c[0] += padding0(d[1] - c[1])
				}
			}
			return operate(+c[0], +d[0], sign, k)
		}
	},
	/**
	 *	js仿sql的group by，对数组进行分类 
	 *  array数据源
	 *  f匿名函数（该函数功能：返回对象的某个指定属性的属性值并存放在数组中）
	 * 	调用示例 groupBy(Array,function(item){
	 *				return [item.id];
	 *			});
	 * 	返回一个根据回调函数分组的数组
	 */
	groupBy: function(array, f) {
		const groups = {};
		array.forEach(function(o) {
			const group = JSON.stringify(f(o));
			groups[group] = groups[group] || [];
			groups[group].push(o);
		});
		return Object.keys(groups).map(function(group) {
			return groups[group];
		});
	},
	/**
	 * 利用两个地点的经纬度计算距离
	 * @param lon1 第一个地点的经度
	 * @param lat1 第一个地点的纬度
	 * @param lon2 第二个地点的经度
	 * @param lat2 第二个地点的纬度
	 */
	getShortDistance: function(lon1, lat1, lon2, lat2) {
		const DEF_PI = 3.14159265359; // PI
		const DEF_2PI = 6.28318530712; // 2*PI
		const DEF_PI180 = 0.01745329252; // PI/180.0
		const DEF_R = 6370693.5; // radius of earth

		let ew1, ns1, ew2, ns2;
		let dx, dy, dew;
		let distance;
		// 角度转换为弧度
		ew1 = lon1 * DEF_PI180;
		ns1 = lat1 * DEF_PI180;
		ew2 = lon2 * DEF_PI180;
		ns2 = lat2 * DEF_PI180;
		// 经度差
		dew = ew1 - ew2;
		// 若跨东经和西经180 度，进行调整
		if (dew > DEF_PI)
			dew = DEF_2PI - dew;
		else if (dew < -DEF_PI)
			dew = DEF_2PI + dew;
		dx = DEF_R * Math.cos(ns1) * dew; // 东西方向长度(在纬度圈上的投影长度)
		dy = DEF_R * (ns1 - ns2); // 南北方向长度(在经度圈上的投影长度)
		// 勾股定理求斜边长
		distance = Math.sqrt(dx * dx + dy * dy).toFixed(0);
		return distance;
	},
	/**数组根据数组对象中的某个属性值进行排序的方法 
	 * 使用例子：objArraySort(objArray, number , false) //表示根据number属性降序排列;若第三个参数不传递，默认表示升序排序
	 * @param objArray 需要排序的对象数组
	 * @param attr 排序的属性 如number属性
	 * @param rev true表示升序排列，false降序排序
	 * */
	objArraySort: function(objArray, attr, rev = true) {
		function sortBy(attr, rev) {
			rev = (rev) ? 1 : -1;
			return function(a, b) {
				a = a[attr];
				b = b[attr];
				if (a < b) {
					return rev * -1;
				}
				if (a > b) {
					return rev * 1;
				}
				return 0;
			}
		}
		return objArray.sort(sortBy(attr, rev));
	},
	
	/**
	 * @desc 函数防抖(所谓防抖，就是指触发事件后在 n 秒内函数只能执行一次，如果在 n 秒内又触发了事件，则会重新计算函数执行时间。)
	 * @param func 函数
	 * @param wait 延迟执行毫秒数
	 * @param immediate true 表立即执行，false 表非立即执行
	 */
	debounce: function(func,wait,immediate) {
		let timeout;

		return function () {
			let context = this;
			let args = arguments;

			if (timeout) clearTimeout(timeout);
			if (immediate) {
				var callNow = !timeout;
				timeout = setTimeout(() => {
					timeout = null;
				}, wait)
				if (callNow) func.apply(context, args)
			}
			else {
				timeout = setTimeout(function(){
					func.apply(context, args)
				}, wait);
			}
		}
	},
	/**
	 * @desc 函数节流 (所谓节流，就是指连续触发事件但是在 n 秒中只执行一次函数。)
	 * @param func 函数
	 * @param wait 延迟执行毫秒数
	 * @param type 1 表时间戳版(时间段开始时执行)，2 表定时器版(时间段结束时执行)    
	 */
	throttle: function(func, wait ,type) {
		if(type===1){
			var previous = 0;
		}else if(type===2){
			var timeout;
		}
		return function() {
			let context = this;
			let args = arguments;
			if(type===1){
				let now = Date.now();

				if (now - previous > wait) {
					func.apply(context, args);
					previous = now;
				}
			}else if(type===2){
				if (!timeout) {
					timeout = setTimeout(() => {
						timeout = null;
						func.apply(context, args)
					}, wait)
				}
			}
		}
	},
	_isMobile:function() {
		let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
		return flag;
	}
}

export default utils
